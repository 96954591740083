import { Document } from "@prismicio/client/types/documents";

export default function readTime(document: Document) {
  const wordsPerMinute = 200;

  let text = "";

  document.data.body.forEach((slice: any) => {
    if (slice.slice_type === "text") {
      slice.primary.text.forEach((element: any) => {
        text = text.concat(element.text, " ");
      });
    }
  });

  let textLength = text.split(" ").length;
  if (textLength > 0) {
    return Math.ceil(textLength / wordsPerMinute);
  }

  return null;
}
