/* This example requires Tailwind CSS v2.0+ */
import { CheckCircleIcon, ExclamationCircleIcon, XIcon } from "@heroicons/react/solid";
import classNames from "classnames";

export default function Alert({
  variant,
  description,
  onDismiss,
}: {
  variant: "success" | "danger";
  description: string;
  onDismiss: any;
}) {
  return (
    <div
      className={classNames("rounded-md p-4", {
        "bg-green-50": variant === "success",
        "bg-red-50": variant === "danger",
      })}
    >
      <div className="flex">
        <div className="flex-shrink-0">
          {variant === "success" && <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />}
          {variant === "danger" && (
            <ExclamationCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
          )}
        </div>
        <div className="ml-3">
          <p
            className={classNames("text-sm font-medium", {
              "text-green-800": variant === "success",
              "text-red-800": variant === "danger",
            })}
          >
            {description}
          </p>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              onClick={onDismiss}
              className={classNames(
                "inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2 ",
                {
                  "bg-green-50 text-green-500 hover:bg-green-100 focus:ring-offset-green-50 focus:ring-green-600":
                    variant === "success",
                  "bg-red-50 text-red-500 hover:bg-red-100 focus:ring-offset-red-50 focus:ring-red-600":
                    variant === "danger",
                },
              )}
            >
              <span className="sr-only">Dismiss</span>
              <XIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
