import Alert from "components/Alert";
import Input from "components/Input";
import { Field, Form, Formik } from "formik";
import i18n from "i18n";
import React, { useState } from "react";
import * as Yup from "yup";

type Values = {
  email: string;
};

export default function Newsletter() {
  const [newsletterSuccess, setNewsletterSuccess] = useState(-1);

  return (
    <div className="relative">
      <div className="mx-auto max-w-lg sm:max-w-3xl lg:max-w-7xl">
        <div className="relative rounded-2xl px-6 py-10 bg-gray-900 overflow-hidden shadow-xl sm:px-12 sm:py-20">
          {newsletterSuccess === 1 && (
            <div className="absolute top-4 left-4 right-4 z-10">
              <Alert
                description={"You successfully signed up for my newsletter, see you soon!"}
                onDismiss={() => {
                  setNewsletterSuccess(-1);
                }}
                variant={"success"}
              />
            </div>
          )}
          {newsletterSuccess === 0 && (
            <div className="absolute top-4 left-4 right-4 z-10">
              <Alert
                description={"An error occurred while signing you up. Please try again later."}
                onDismiss={() => {
                  setNewsletterSuccess(-1);
                }}
                variant={"danger"}
              />
            </div>
          )}
          <div aria-hidden="true" className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
            <svg
              className="absolute inset-0 h-full w-full"
              preserveAspectRatio="xMidYMid slice"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 1463 360"
            >
              <path
                className="text-gray-500 text-opacity-40"
                fill="currentColor"
                d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
              />
              <path
                className="text-gray-700 text-opacity-40"
                fill="currentColor"
                d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
              />
            </svg>
          </div>
          <div className="relative">
            <div className="sm:text-center">
              <h2 className="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
                {i18n.t("Stay in the loop.")}
              </h2>
              <p className="mt-6 mx-auto max-w-2xl text-lg text-gray-50">
                {i18n.t(
                  "On the rare occassion I write an article, or have something to announce, I will send out a small newsletter. Personally written, by me!",
                )}
              </p>
            </div>
            <Formik<Values>
              validationSchema={Yup.object({
                email: Yup.string()
                  .email("Please enter a valid e-mail address.")
                  .required("Please enter a valid e-mail address."),
              })}
              initialValues={{
                email: "",
              }}
              onSubmit={async values => {
                try {
                  await fetch("api/newsletter-signup", {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      email: values.email,
                    }),
                  });

                  setNewsletterSuccess(1);
                } catch (e) {
                  setNewsletterSuccess(0);
                }
              }}
            >
              <Form>
                <div className="mt-12 sm:mx-auto sm:max-w-lg sm:flex">
                  <div className="min-w-0 flex-1">
                    <label htmlFor="email" className="sr-only">
                      {i18n.t("Email address")}
                    </label>
                    <Field
                      id="email"
                      name="email"
                      type="email"
                      placeholder={i18n.t("Enter your email")}
                      component={Input}
                    />
                  </div>
                  <div className="mt-4 sm:mt-0 sm:ml-3">
                    <button
                      type="submit"
                      className="block w-full rounded-md px-5 py-3 bg-gradient-to-r from-[#FF512F] to-[#FFC837] text-base font-medium text-white shadow focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary-600 sm:px-10"
                    >
                      {i18n.t("Sign up")}
                    </button>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
