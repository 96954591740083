import classNames from "classnames";
import { FieldProps } from "formik";
import get from "lodash/get";
import { DetailedHTMLProps, HTMLAttributes, ReactNode } from "react";

export default function Input({
  field,
  form,
  className,
  ...props
}: FieldProps & DetailedHTMLProps<HTMLAttributes<HTMLInputElement>, HTMLInputElement>) {
  const error = get(form.errors, field.name);
  const isInvalid = !!get(form.touched, field.name) && !!error;

  return (
    <>
      <div className={classNames("relative flex rounded-md shadow-sm", className)}>
        <input
          id={field.name}
          className={classNames("block w-full rounded-md px-5 py-3 text-base shadow-sm focus:outline-none", {
            "text-gray-900  border-red-300 placeholder-red-300 focus:border-red-500 focus:ring-2 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-red-600":
              isInvalid,
            "text-gray-900 placeholder-gray-500 focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary-600":
              !isInvalid,
          })}
          aria-invalid={isInvalid ? "true" : undefined}
          aria-describedby={isInvalid ? `${field.name}-error` : undefined}
          {...field}
          {...props}
        />
        {isInvalid && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <svg className="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        )}
      </div>
      {isInvalid && (
        <p className="mt-2 text-sm text-red-600" id={`${field.name}-error`} data-error>
          {error}
        </p>
      )}
    </>
  );
}
