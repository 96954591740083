import React, { HTMLAttributes } from "react";
import classNames from "classnames";

export default function Container({
  children,
  className,
}: React.PropsWithChildren<HTMLAttributes<HTMLDivElement>>) {
  return (
    <div className={classNames("max-w-4xl w-full px-4 mx-auto py-6 sm:px-6 lg:px-8", className)}>
      {children}
    </div>
  );
}

export function WideContainer({
  children,
  className,
}: React.PropsWithChildren<HTMLAttributes<HTMLDivElement>>) {
  return (
    <div
      className={classNames(
        "max-w-lg sm:max-w-3xl lg:max-w-7xl mx-auto w-full px-4 mx-auto py-6 sm:px-6 lg:px-8",
        className,
      )}
    >
      {children}
    </div>
  );
}

export function FullWidthContainer({
  children,
  className,
}: React.PropsWithChildren<HTMLAttributes<HTMLDivElement>>) {
  return <div className={classNames("max-w-full w-full overflow-hidden", className)}>{children}</div>;
}
