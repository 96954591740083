import { SkipNavContent } from "@reach/skip-nav";
import { DetailedHTMLProps, HTMLAttributes, PropsWithChildren } from "react";

export default function Main({
  children,
  ...props
}: PropsWithChildren<DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>>) {
  return (
    <main {...props}>
      <SkipNavContent />
      {children}
    </main>
  );
}
